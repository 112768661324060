import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";
const app = firebase.initializeApp({
  apiKey: "AIzaSyAy0HydxJY97p3ZanJjyu5oVvu6mtKcY1Q",
  authDomain: "malloc-privacy.firebaseapp.com",
  projectId: "malloc-privacy",
  storageBucket: "malloc-privacy.firebasestorage.app",
  messagingSenderId: "152412313635",
  appId: "1:152412313635:web:736f64c265ff8b5eb87996"
})


export const auth = app.auth()
const analytics = getAnalytics(app);
export default app
