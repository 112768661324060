import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function Profile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
        .then(() => {
          history.push("/");
        })
        .catch(() => {
          setError("Failed to update account");
        })
        .finally(() => {
          setLoading(false);
        });
  }

  return (
      <div className="page bg-secondary" id="content-profile">
        <header className="header-light d-none"></header>

        <div className="container">
          <section className="min-vh-100 position-relative overflow-hidden py-5">
            <div className="row pt-sm-2 pt-lg-0">
              <aside className="col-lg-3 pe-lg-4 pe-xl-5 mt-n3">
                <div className="position-lg-sticky top-0">
                  <div className="d-none d-lg-block" style={{ paddingTop: "105px" }}></div>
                  <div className="offcanvas-lg offcanvas-start" id="sidebarAccount2">
                    <button
                        className="btn-close position-absolute top-0 end-0 mt-3 me-3 d-lg-none"
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                    <div className="offcanvas-body">
                      <div className="pb-2 pb-lg-0 mb-4 mb-lg-5">
                        <img
                            className="d-block rounded mb-2 profile-image"
                            src="assets/img/avatar/02.jpg"
                            width="60"
                            alt="User Avatar"
                        />
                        <h3 className="h5 mb-1 user-nickname"></h3>ss
                        <p className="fs-sm text-muted mb-0 user-email"></p>
                      </div>
                      <nav className="nav flex-column pb-2 pb-lg-4 mb-3">


                          <i className="ai-user-check fs-5 opacity-60 me-2"></i>Overview

                          <i className="ai-settings fs-5 opacity-60 me-2"></i>Settings

                          <span>Download app</span>

                      </nav>
                      <nav className="nav flex-column">

                          <i className="ai-logout fs-5 opacity-60 me-2"></i>Log out

                      </nav>
                    </div>
                  </div>
                </div>
              </aside>

              <div className="container py-5 my-lg-3 my-xl-4 my-xxl-5 col-lg-9 pb-2 pb-sm-4">
                <h5 className="fs-xl mb-4 pt-5">Overview</h5>
                <section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
                  <div className="card-body">
                    <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
                      <h2 className="h4 mb-0">
                        Hi, <span className="user-nickname"></span>
                      </h2>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="page-spinner" id="loading-spinner"></div>
                      {/* Add content for "No plan" or "Subscription row" */}
                    </div>
                  </div>
                </section>

                <div className="container py-5 my-lg-4 my-xl-5 my-xxl-5" id="plans">
                  <h3 className="text-center pt-md-3 pt-lg-4 pb-xxl-2 mt-xl-3">Pricing Plans</h3>
                  <p className="pb-4 mb-md-2 mb-lg-3 text-center fs-md">
                    Select your plan, create an account, and protect more than one device for the same price!
                  </p>

                  {/* Example Plan Card Structure */}
                  <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 pb-4 d-flex justify-content-center">
                    {/* Plan Card */}
                    <div className="col-12 pricing-card px-4 d-none scroll_container_sub pt-3 pt-xl-0" style={{ maxWidth: "23rem" }} id="card-monthly">
                      <div className="card border-3 border-dark h-100 py-4">
                        <div className="card-body w-100 mx-auto px-4 py-3" style={{ maxWidth: "23rem" }}>
                          <h7 className="fs-lg">Monthly</h7>
                          <div className="h5 text-primary pt-3 mb-1 pb-0" id="monthly-price">Loading price...</div>
                          <div className="align-content-center d-flex justify-content-center px-2" id="button-monthly">
                            <a className="btn btn-dark fs-xs" id="monthly_button" href="#">
                              Get monthly plan
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Add more Plan Cards as needed */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  );
}
