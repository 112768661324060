import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";

export default function Logout() {
  const { logout } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    // Clear any stored user data on mount
    localStorage.removeItem("user");
    localStorage.removeItem("metadata");

    const handleLogout = async () => {
      try {
        setError("");
        await logout(); // Calls Firebase Auth's signOut method

        // Refresh the page and redirect to the home page
        history.push("/");
        window.location.reload();
      } catch {
        setError("Failed to log out");
      }
    };

    handleLogout();
  }, [logout, history]);

  return (
      <>
        {error && <div className="alert alert-danger">{error}</div>}
      </>
  );
}
