import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { getAuth, GoogleAuthProvider, signInWithPopup, browserPopupRedirectResolver } from "firebase/auth";

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // Redirect if user is already logged in
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            window.location.href = "/manage-subscription";
        }
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError("");
            setLoading(true);
            const user = await login(emailRef.current.value, passwordRef.current.value);
            localStorage.setItem("user", JSON.stringify(user.user));
            window.location.href = "/manage-subscription";
        } catch {
            setError("Failed to log in");
        }

        setLoading(false);
    }

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const auth = getAuth();
            signInWithPopup(auth, provider, browserPopupRedirectResolver)
                .then((result) => {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const uid = result.user.uid;


                    // Save user data to localStorage
                    localStorage.setItem("user", JSON.stringify(result.user));

                    // Fetch metadata
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    const raw = JSON.stringify({ uid });

                    const requestOptions = {
                        method: "POST",
                        mode: "cors",
                        headers: myHeaders,
                        redirect: "follow",
                        body: raw,
                    };

                    fetch("https://api.mallocapp.com/get_metadata", requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            const metadata = JSON.parse(result);
                            localStorage.setItem("metadata", JSON.stringify(metadata));
                            window.location.href = "/manage-subscription";
                        })
                        .catch((err) => {
                            console.debug("Something went wrong", err);
                        });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const email = error.customData?.email;
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.error("Error during Google sign-in:", errorCode, errorMessage);
                });
        } catch (error) {
            setError("Failed to log in with Google");
            console.error("Google Login Error:", error);
        }
    };

    return (
        <Container style={{marginTop: '10%'}}>
            <h3 className="mb-3 p-5 align-items-center">Dont' have an account? <Link className="text-primary text-decoration-underline" to="/signup">Sign Up</Link>
            </h3>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Login to Malloc</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required/>
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required/>
                        </Form.Group>
                        <Button
                            disabled={loading}
                            className="mt-3 mx-auto d-flex align-items-center justify-content-center"
                            style={{width: '50%'}}
                            type="submit"
                        >
                            Login
                        </Button>
                    </Form>
                    <Button
                        variant="outline-primary"
                        className="mt-3 w-100 d-flex align-items-center justify-content-center"
                        onClick={handleGoogleLogin}
                        style={{backgroundColor: '#ffffff', borderColor: '#dddddd'}}
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/220px-Google_%22G%22_logo.svg.png"
                            alt="Google logo"
                            style={{width: '20px', marginRight: '10px'}}
                        />
                        Login with Google
                    </Button>
                    <div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                </Card.Body>
            </Card>

        </Container>
    );
}
