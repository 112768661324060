import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./UpdateProfile.css";
import "./theme.css";

export default function UpdateProfile() {
    const [user, setUser] = useState(null);
    const [promoCode, setPromoCode]= useState(null)
    const [reward, setReward] = useState(null);
    const [affiliate, setAffiliate] = useState(null);
    const [usermetadata, setMetadata] = useState(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // Map currency codes to symbols
    const currencySymbolMap = {
        usd: "$",
        eur: "€",
        gbp: "£",
        jpy: "¥",
        aud: "A$",
        cad: "C$",
        chf: "CHF",
        cny: "¥",
        sek: "kr",
        nzd: "NZ$",
        mxn: "MX$",
        sgd: "S$",
        hkd: "HK$",
        nok: "kr",
        krw: "₩",
        try: "₺",
        inr: "₹",
        rub: "₽",
        zar: "R",
        brl: "R$",
        twd: "NT$",
        dkk: "kr",
        pln: "zł",
        thb: "฿",
        idr: "Rp",
        huf: "Ft",
        czk: "Kč",
        ils: "₪",
        clp: "$",
        php: "₱",
        aed: "د.إ",
        cop: "$",
        sar: "﷼",
        myr: "RM",
        ron: "lei"
    };

// Helper function to get the symbol for a currency code
    const getCurrencySymbol = (currencyCode) => {
        return currencySymbolMap[currencyCode.toLowerCase()] || currencyCode.toUpperCase();
    };

    // Fetch user data from localStorage on component mount
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) setUser(storedUser);

        const reward = localStorage.getItem("rewardful");
        if (reward) setReward(reward);

        const storedPromo = localStorage.getItem("promo");
        if (storedPromo) setPromoCode(storedPromo);

        const affiliate = localStorage.getItem("affiliate");

        if (affiliate) {
            setAffiliate(affiliate);

        }
    }, []);

    // Fetch metadata from API and store in localStorage
    useEffect(() => {
        const fetchMetadata = async () => {
            const storedUser = JSON.parse(localStorage.getItem("user"));
            if (storedUser) {
                const raw = JSON.stringify({ uid: storedUser.uid });
                const myHeaders = new Headers({ "Content-Type": "application/json" });

                try {
                    const response = await fetch("https://api.mallocapp.com/get_metadata", {
                        method: "POST",
                        mode: "cors",
                        headers: myHeaders,
                        body: raw,
                    });
                    const result = await response.json();
                    localStorage.setItem("metadata", JSON.stringify(result));
                    setMetadata(result);
                } catch (error) {

                }
            }
        };

        if (!usermetadata) fetchMetadata();
    }, [usermetadata]);

    // Fetch subscription details if user metadata contains stripe_customer_id
    useEffect(() => {
        if (usermetadata?.current_claims?.app_metadata?.stripe_customer_id) {
            const device_id = usermetadata.current_claims.app_metadata.stripe_customer_id;

            axios
                .post("https://api.mallocapp.com/get_subscription_details", { device_id })
                .then((response) => setSubscriptionDetails(response.data))
                .catch((error) => console.error("Error fetching subscription details:", error));
        }
    }, [usermetadata]);

    // Fetch available plans if no active subscription
    useEffect(() => {
        if (subscriptionDetails && !subscriptionDetails.active_entitlement_name) {
            setLoading(true);
            axios
                .post("https://api.mallocapp.com/get_subs_live", { ip: "46.199.91.178" })
                .then((response) => {
                    setPlans(response.data);
                    setLoading(false);
                })
                .catch((error) => {

                    setLoading(false);
                });
        }
    }, [subscriptionDetails]);

    async function handleCheckout(price_id) {
        const apiUrl = "https://api.mallocapp.com/checkout";
        const device_id = usermetadata.current_claims.app_metadata.stripe_customer_id;
        console.log(apiUrl, device_id);
        let requestData ;
        if(reward) {
         requestData = {
            customer_id: device_id,
            price: price_id, // Use the dynamic priceId
            client: reward,
        };}
        else  {
        requestData = {
            customer_id: device_id,
            price: price_id
        };
        }
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.URL) {
                window.location.href = data.URL; // Redirect to the URL from the response
            } else {

            }
        } catch (error) {

        }
    };


    return (
        <div>



            <section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
                <div className="card-body text-center">

                    <div className="container py-5 my-lg-4 my-xl-5 my-xxl-5">

                        <h5>
                            Hi, <span
                            className="user-nickname">{usermetadata?.provider_info?.[0]?.display_name || user?.email} , </span>
                        </h5>
                        {affiliate?.name && (
                       <h6> friend of {affiliate?.name}</h6>
                        )}
                        {subscriptionDetails?.active_entitlement_name ? (
                            <div className="subscription-details">
                                <h5>Subscription Active</h5>
                                <p>Expires on: {subscriptionDetails.expires_on}</p>
                                <p>Renewal Date: {subscriptionDetails.renewal_date}</p>

                                {subscriptionDetails.management_url && (
                                    <button
                                        className="btn btn-primary mt-3"
                                        onClick={() => window.open(subscriptionDetails.management_url, "_blank")}
                                    >
                                        Manage your subscription
                                    </button>
                                )}
                            </div>
                        ) : (
                            <div className="plans1">
                                <h6>You don't have an active subscription, let's change that!</h6>
                                {promoCode && (
                                    <section className="promo-banner bg-primary text-dark py-3">
                                        <div className="container text-center">
                                            <h5>Special Offer! Use Promo Code "{promoCode}"  at checkout for 50% Off on any plan !</h5>
                                        </div>
                                    </section>
                                )}

                                <h3 className="text-center pt-md-3 pt-lg-4 pb-xxl-2 mt-xl-3">Pricing Plans</h3>
                                <h6 className="pb-4 mb-md-2 mb-lg-3 text-center fs-md">
                                    Select your plan, create an account, and protect more than one device for the same
                                    price!
                                </h6>

                                {loading ? (
                                    <div className="spinner"></div>
                                ) : (
                                    <div
                                        className="row row-cols-1 row-cols-md-2 row-cols-xl-3 pb-4 d-flex justify-content-center">
                                        {plans.map((plan, index) => {
                                            const monthlyPrice =
                                                plan.duration === "P1M"
                                                    ? plan.price
                                                    : plan.duration === "P6M"
                                                        ? (plan.price / 6).toFixed(2)
                                                        : (plan.price / 12).toFixed(2);

                                            const planName =
                                                plan.duration === "P1M" ? "Monthly" : plan.duration === "P6M" ? "Six Months" : "Yearly";

                                            const isYearly = plan.duration === "P1Y";
                                            const currencySymbol = getCurrencySymbol(plan.currency_symbol);

                                            return (
                                                <div
                                                    className={`col-12 pricing-card px-4 scroll_container_sub pt-3 pt-xl-0 ${
                                                        isYearly ? 'yearly-highlight' : ''
                                                    }`}
                                                    style={{maxWidth: "23rem"}}
                                                    key={index}
                                                >
                                                    <div
                                                        className={`card border-3 ${isYearly ? 'border-highlight' : 'border-regular'} h-100 py-4`}>
                                                        {isYearly && (
                                                            <div className="hottest-deal-badge">
                                                                🔥 Hottest deal
                                                            </div>
                                                        )}
                                                        <div className="card-body w-100 mx-auto pt-1"
                                                             style={{maxWidth: "23rem"}}>
                                                            <h3 style={{color: "white"}}>{planName}</h3>
                                                            <div className="h3 text-primary pt-3 mb-2">
                                                                {currencySymbol}{monthlyPrice} <span
                                                                className="per-month">/month</span>
                                                            </div>
                                                            <p className="total-price">
                                                                {currencySymbol}{plan.price} / {planName.toLowerCase()},
                                                                VAT included.
                                                            </p>
                                                            {isYearly && (
                                                                <span className="discount-badge">44%</span>
                                                            )}

                                                            {/* Static Features List */}
                                                            <div className="features-list">
                                                                <p><img className="feature-icon"
                                                                        src="../assets/img/landing/why-malloc/iconsblue (1).svg"
                                                                        alt="icon"/> Spyware Protection</p>
                                                                <p><img className="feature-icon"
                                                                        src="../assets/img/landing/why-malloc/iconsblue (1).svg"
                                                                        alt="icon"/> Threats Block</p>
                                                                <p><img className="feature-icon"
                                                                        src="../assets/img/landing/why-malloc/iconsblue (1).svg"
                                                                        alt="icon"/> Secure VPN</p>
                                                                <p><img className="feature-icon"
                                                                        src="../assets/img/landing/why-malloc/iconsblue (1).svg"
                                                                        alt="icon"/> Sensors Monitoring</p>
                                                                <p><img className="feature-icon"
                                                                        src="../assets/img/landing/why-malloc/iconsblue (1).svg"
                                                                        alt="icon"/> Protect up to 3 devices</p>
                                                            </div>

                                                            <div
                                                                className="align-content-center d-flex justify-content-center">
                                                                <button
                                                                    className="btn btn-primary mt-3"
                                                                    key={plan.price_id}
                                                                    onClick={() => handleCheckout(plan.price_id)}
                                                                >
                                                                  Subscribe
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}
